import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { Shitterface } from "./styled";

const Container = (props: { children: any; url: string; arrow?: boolean }) => {
  const containerRef = useRef(null);

  return (
    <ContainerWrap ref={containerRef} url={props.url}>
      {props.children}

      {props.arrow && (
        <Arrow xmlns="http://www.w3.org/2000/svg" viewBox="0 0 177.16 88.58">
          <title>arrowdown</title>
          <polygon points="177.16 0 156.34 0 88.71 67.63 21.08 0 0 0 88.58 88.58 177.16 0 177.16 0" />
        </Arrow>
      )}
    </ContainerWrap>
  );
};

const Arrow = styled.svg`
  position: absolute;
  width: 60px;
  fill: white;
  bottom: 2em;
  margin: auto;
  left: 0;
  right: 0;
  height: 60px;
  padding: 10px;
  border-radius: 100%;
`;

export const ContainerWrap = styled.div<Shitterface>`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  height: 100vh;
  position: relative;
  -webkit-transform: translateZ(0);
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
  &:before {
    content: "";
    background-image: url(${(props) => props.url});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    transition: all 2s ease-in-out;
    transform: scale(1.05) rotate(0deg);
  }
` as React.FC<Shitterface>;

export default Container;
