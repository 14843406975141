import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import Container from './shared/container';
import PageHeader from './shared/page-header';

const NotFound = (props: any) => {
  if (props.context) {
    props.context.statusCode = 404;
  }
  return (
    <>
      <Helmet>
        <title>Not found</title>
      </Helmet>
      <Container url="https://img.wedin.no/api/images/188?width=2000">
        <PageHeader color="BLACK" showlogo />
        <Content>Page not found - 404</Content>
      </Container>
    </>
  );
};

const Content = styled.div`
  position: absolute;
  top: 45vh;
  color: #fff;
  background: rgba(0, 0, 0, 0.4);
  left: 0;
  text-align: center;
  max-width: 800px;
  width: 80%;
  right: 0;
  font-family: 'rajdhani', sans-serif;
  font-size: 1em;
  padding: 0.8em 0 0.7em;
  margin: auto;
  letter-spacing: 5px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 35px;

  @media (min-width: 600px) {
    width: 50%;
    font-size: 2em;
  }
`;

export default NotFound;
